table {
  @apply min-w-full text-xs relative p-2;

  thead {

    th {
      @apply whitespace-nowrap py-3.5 pl-4 text-left text-black shadow-[inset_0_-1px_0] shadow-[#D9D9D9] sm:pl-0 sticky top-0 bg-white cursor-pointer select-none;
      font-size: 10px;
      line-height: 14px;
      font-weight: 400;

      &.active {
        @apply font-semibold;
      }

      .cell {
        @apply flex items-center gap-1 text-center;
      }
    }
  }

  tbody {
    @apply bg-white text-gray-500 text-sm cursor-pointer;

    td {
      @apply whitespace-nowrap py-2 text-xs text-black font-semibold h-12 cursor-pointer;

      .survey-img {
        @apply w-8 h-8 object-cover bg-[#D9D9D9];
      }

      .avatar-img {
        @apply w-5 h-5 rounded-full;
      }
    }
  }

  tr.hovered {
    @apply bg-[#F2F8FE];
  }

  tr.active {
    @apply bg-[#DFEBFF];
  }
}
