/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import "@angular/cdk/overlay-prebuilt.css";
@import "~@natural-apptitude/coreo-mapbox/dist/index.css";

@font-face {
  font-family: "Core Sans C";
  src:
    url("/assets/fonts/coresansc-35light.woff") format("woff"),
    url("/assets/fonts/coresansc-35light.woff2") format("woff2");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Core Sans C";
  src:
    url("/assets/fonts/coresansc-45regular.woff") format("woff"),
    url("/assets/fonts/coresansc-45regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Core Sans C";
  src:
    url("/assets/fonts/coresansc-65bold.woff") format("woff"),
    url("/assets/fonts/coresansc-65bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}

@import "./theme/tables.scss";

.panel {
  @apply bg-white rounded-lg border p-4;
  border-color: #c4cdd7;

  &-light {
    background-color: #f2f8fe;
  }

  &-no-padding {
    @apply p-0;
  }
}

.button-blue {
  @apply bg-[#0069DF] rounded-md text-white text-center cursor-pointer font-semibold
  hover:bg-[#005ECB] active:bg-[#004B9B];

  &.disabled {
    @apply pointer-events-none opacity-30;
  }
}

.button-white {
  @apply bg-white rounded-md text-[#0069DF] text-center cursor-pointer font-semibold
  shadow-[inset_0_0_0_1px] shadow-[#0069DF] hover:bg-[#F2F8FE] active:bg-[#DFEBFF];

  &.disabled {
    @apply pointer-events-none opacity-30;
  }
}

.mapboxgl-ctrl-switch {
  background: #fff;
  height: 29px;
  width: 29px;
  border-radius: 4px;
  background-repeat: no-repeat !important;
  background-size: cover !important;

  &.streets {
    background: url(/assets/satellite.png);
  }
  &.satellite {
    background: url(/assets/streets.png);
  }
}

.cdk-dialog-container {
  outline: none !important;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.project-map-baseline,
app-project-pre-survey-map {
  .mapboxgl-ctrl-bottom-left {
    bottom: 44px;
  }
}

.project-map {
  .mapboxgl-ctrl-top-left {
    // display: flex;
    // align-items: flex-start;
    .mapbox-edit-toolbar {
      display: none;
    }
  }

  .mapboxgl-ctrl-top-right,
  .mapboxgl-ctrl-bottom-right {
    right: 40px;
  }
}

.project-map-popup {
  .mapboxgl-popup-content {
    @apply rounded-lg text-[#444] p-5 pointer-events-none;
    box-shadow: 0px 4px 14px 0px rgba(14, 15, 17, 0.15);
  }
}

app-project-export-map.printing {
  .mapboxgl-ctrl-map-settings {
    display: none !important;
  }
}

.boxdraw {
  background: rgba(0, 105, 223, 0.1);
  border: 2px solid #3887be;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
}

.project-container{
  @apply w-full grid;

  height: calc(100% - 48px);

  grid-template-rows: 1fr 1fr;

  grid-template-columns: 100vw;

  transition: all ease-in-out 0.5s;
}

app-project-baseline-map, app-project-pre-survey-map{
  grid-row: 1;
  grid-column: 1 / span 1;
}

app-project-baseline-table, app-project-pre-survey-table, app-project-empty {
  @apply w-full h-full block overflow-y-auto px-4 bg-white;
  grid-column: 1;
  grid-row: 2;
}

app-project-sidebar{
  grid-column: 2;
  grid-row: 1 / span 2;
}


.open .project-container{
  grid-template-columns: calc(100vw - 328px);
}

app-project-baseline.map-max, app-project-pre-survey.map-max{
  .project-container{
    grid-template-rows: 20fr minmax(98px, 1fr);
  }
}
